<template>
    <div class="ticket-main">
        <div class="ticket__ticket-info-wrapper">
            <van-row class="ticket__ticket-account-title-wrapper">
                <span>使用卡券</span>
            </van-row>
            <!-- 图片条形码128卡券 -->
            <van-row v-if="!!!goodsType || 'BARCODE_128' === goodsType || 'BARCODE_128_QR_CODE' === goodsType " type="flex" justify="center" class="ticket_ticket-info-barcode128-wrapper">
                <van-image width="320" height="70" :src="barcode128Pic"/>
            </van-row>
            <!-- 图片二维码券码 -->
            <van-row v-if="!!!goodsType || 'BARCODE_128_QR_CODE' === goodsType || 'QR_CODE' === goodsType" type="flex" justify="center">
                <van-image width="160" height="160" :src="qrPic"/>
            </van-row>
            <!-- 券码字符串 -->
            <van-row v-if="!!!goodsType || ('BARCODE_128' !== goodsType && 'BARCODE_128_QR_CODE' !== goodsType && 'QR_CODE' !== goodsType)" class="ticket__ticket-info-str-wrapper">
                <div class="ticket__ticket-info-str">
                    {{account}}
                </div>
            </van-row>
             <!-- 券码字符串 -->
            <van-row v-if="!!password" class="ticket__ticket-info-str-wrapper">
                <div class="ticket__ticket-info-str">
                    {{password}}
                </div>
            </van-row>
            <!-- 质保期 -->
            <!-- <van-row type="flex" justify="center" class="ticket__ticket-guarantee-time"> -->
                <!-- 质保期: {{guaranteeTime}} -->
                <!-- 质保期: 24小时内 -->
            <!-- </van-row> -->
            <!-- 卡券的分隔符 -->
            <van-row>
                <tic-divider/>
            </van-row>
            <van-row class="ticket__ticket-warning-title-wrapper">卡券须知</van-row>
            <van-row class="ticket__ticket-warning-wrapper">
                {{useMethodTip(goodsType)}}<br/>
                2:可能存在新开门店、机具维护等原因导致暂时无法提供服务，
                具体兑换情况请以门店实际经营为准。<br/>
                3:卡券请于质保期内使用，一经购买，非质量问题不予退换。<br/>
                4:卡券核销如遇问题请联系客服
            </van-row>
        </div>
    </div> 
</template>

<script>
import {NavBar, Image, Row, Col} from "vant";
import TicketDivider from "@/components/TicketDivider.vue";
import request from "@/utils/request.js";

export default {
    name: "Ticket",
    components: {
        [NavBar.name]: NavBar,
        [Image.name]: Image,
        [Row.name]: Row,
        [Col.name]: Col,
        [TicketDivider.name]: TicketDivider
    },
    data() {
        return {
            account: undefined,
            password: undefined,
            goodsType: undefined,
            guaranteeTime: '72小时内',
            qrPic: undefined,
            barcode128Pic: undefined,
        };
    },
    mounted() {
        this.account = this.$route.params.account;
        this.goodsType = this.$route.query.goodsType;
        this.password = this.$route.query.psw;
        this.transformAccount(this.account);
    },
    methods: {
        async transformAccount() {
            const {data} = await request.get(`/order/account2Pic?account=${this.account}`);
            if (data) {
                this.account = data.account;
                // this.password = data.password;
                this.qrPic = data.qrPic;
                this.barcode128Pic= data.barcode128;
            } else {
                this.account = "尚未发货";
            }
        },
        useMethodTip(goodsType) {
            switch(goodsType) {
                case "BARCODE_128":
                case "QR_CODE":
                case "BARCODE_128_QR_CODE":
                    return "1:将卡券给服务人员扫码即可进行兑换消费。";
                default:
                    return "1:复制卡密-对应卡券APP或小程序-兑换。";

            }
        }
    }
}
</script>

<style lang="less">
@color-theme: #17161c;
@color-border:#00000026;
@font-size-title: 16px;
@font-weight-title: 500;
@line-height-title: 24px;
@font-color-title: #000000d9;

@font-size-normal: 14px;
@font-weight-normal: 400;
@line-height-normal: 22px;
@font-color-normal: #000000a6;

.ticket-main {
    padding: 8px 0px;
    min-height: 100vh;
    background-color: @color-theme;

    .van-nav-bar {
        background-color: @color-theme;
    }
    .van-nav-bar.van-hairline--bottom::after {
        border: none;
    }

    // 导航栏文字
    .van-nav-bar__text {
        color: white;
    }

    .van-icon.van-nav-bar__arrow {
        color: white;
    }

    .van-nav-bar__title {
        color: white;
    }
}

.ticket__ticket-info-wrapper {
    border-radius: 8px;
    background-color: white;
    margin: 0px 8px 0 8px;
}

.ticket__ticket-account-title-wrapper {
    padding: 16px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
}
.ticket_ticket-info-barcode128-wrapper {
    padding: 16px;
}
.ticket__ticket-info-str-wrapper {
    padding: 16px;
}

.ticket__ticket-info-str {
    border: 2px solid @color-border;
    border-radius: 4px;
    text-align: center;
    padding: 16px 8px;
    word-break: break-all;
}

.ticket__ticket-guarantee-time {
    font-size: @font-size-normal;
    color: @font-color-title;
    line-height: @line-height-normal;
}

.ticket__ticket-warning-title-wrapper {
    padding: 16px 16px 8px 16px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
}

.ticket__ticket-warning-wrapper {
    padding: 0 16px 16px 16px;
    font-size: @font-size-normal;
    color: @font-color-normal;
    line-height: @line-height-title;
    letter-spacing: 1px;
}
</style>